import { memo, SVGProps } from 'react';

const OpenInNewIcon2 = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M11.5 12.25H2.5C2.05 12.25 1.75 11.875 1.75 11.5V2.5C1.75 2.05 2.05 1.75 2.5 1.75H6.25C6.7 1.75 7 1.45 7 1C7 0.55 6.7 0.25 6.25 0.25H1.75C0.925 0.25 0.25 0.925 0.25 1.75V12.25C0.25 13.075 0.925 13.75 1.75 13.75H12.25C13.075 13.75 13.75 13.075 13.75 12.25V7.75C13.75 7.3 13.375 7 13 7C12.625 7 12.25 7.3 12.25 7.75V11.5C12.25 11.875 11.875 12.25 11.5 12.25ZM8.5 1C8.5 1.45 8.8 1.75 9.25 1.75H11.2L4.375 8.575C4.075 8.875 4.075 9.325 4.375 9.625C4.675 9.925 5.125 9.925 5.425 9.625L12.25 2.8V4.75C12.25 5.2 12.625 5.5 13 5.5C13.375 5.5 13.75 5.2 13.75 4.75V1C13.75 0.625 13.375 0.25 13 0.25H9.25C8.8 0.25 8.5 0.625 8.5 1Z'
      fill='#0C55B8'
    />
  </svg>
);
const Memo = memo(OpenInNewIcon2);
export { Memo as OpenInNewIcon2 };
