import { memo } from 'react';
import type { FC } from 'react';

import resets from '../../_resets.module.css';
import { FileFile_download_24dp } from '../FileFile_download_24dp/FileFile_download_24dp';
import { HardwarePrinter_24dp } from '../HardwarePrinter_24dp/HardwarePrinter_24dp';
import classes from './Button_TypeSecondarySizeMedium.module.css';

interface Props {
  className?: string;
  classes?: {
    root?: string;
  };
}
/* @figmaId 1:466 */
export const Button_TypeSecondarySizeMedium: FC<Props> = memo(function Button_TypeSecondarySizeMedium(props = {}) {
  return (
    <button
      className={`${resets.storybrainResets} ${props.classes?.root || ''} ${props.className || ''} ${classes.root}`}
    >
      <div className={classes.buttonLabel}>Button label</div>
    </button>
  );
});
