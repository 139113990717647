import { memo } from 'react';
import type { FC, ReactNode } from 'react';

import resets from '../../_resets.module.css';
import { FileFile_download_24dp } from '../FileFile_download_24dp/FileFile_download_24dp';
import { HardwarePrinter_24dp } from '../HardwarePrinter_24dp/HardwarePrinter_24dp';
import classes from './Button_TypePrimarySizeMediumSt.module.css';

interface Props {
  className?: string;
  classes?: {
    root?: string;
  };
  text?: {
    buttonLabel?: ReactNode;
  };
}
/* @figmaId 1:350 */
export const Button_TypePrimarySizeMediumSt: FC<Props> = memo(function Button_TypePrimarySizeMediumSt(props = {}) {
  return (
    <button
      className={`${resets.storybrainResets} ${props.classes?.root || ''} ${props.className || ''} ${classes.root}`}
    >
      {props.text?.buttonLabel != null ? (
        props.text?.buttonLabel
      ) : (
        <div className={classes.buttonLabel}>Button label</div>
      )}
    </button>
  );
});
