import { memo } from 'react';
import type { FC, ReactNode } from 'react';

import resets from '../../_resets.module.css';
import { Link_SizeMediumStateDefaultBGL } from '../Link_SizeMediumStateDefaultBGL/Link_SizeMediumStateDefaultBGL';
import { NavigationOpen_in_new_24dp } from '../NavigationOpen_in_new_24dp/NavigationOpen_in_new_24dp';
import classes from './Footer_TypeDesktopLogoFalse.module.css';
import { OpenInNewIcon } from './OpenInNewIcon';
import { OpenInNewIcon2 } from './OpenInNewIcon2';

interface Props {
  className?: string;
  classes?: {
    content?: string;
    root?: string;
  };
  hide?: {
    rightIcon?: boolean;
    rightIcon2?: boolean;
    rightIcon3?: boolean;
    rightIcon4?: boolean;
    rightIcon5?: boolean;
    rightIcon6?: boolean;
    link?: boolean;
    link2?: boolean;
    link3?: boolean;
    link4?: boolean;
    link5?: boolean;
    link6?: boolean;
  };
  text?: {
    text?: ReactNode;
    text2?: ReactNode;
    text3?:ReactNode;
    thisIsPlaceholderTextThisIsWhe?: ReactNode;
    _2023OptumIncAllRightsReserved?: ReactNode;
  };
}
/* @figmaId 1:138 */
export const Footer_TypeDesktopLogoFalse: FC<Props> = memo(function Footer_TypeDesktopLogoFalse(props = {}) {
  return (
    <div className={`${resets.storybrainResets} ${props.classes?.root || ''} ${props.className || ''} ${classes.root}`}>
      <div className={classes.topBorder}></div>
      <div className={`${props.classes?.content || ''} ${classes.content}`}>
        <div className={classes.copyrightLinks}>
          <div className={classes.copyrightLine}>
            {props.text?._2023OptumIncAllRightsReserved != null ? (
              props.text?._2023OptumIncAllRightsReserved
            ) : (
              <div className={classes._2023OptumIncAllRightsReserved}>© 2023 Optum Inc. All rights reserved. </div>
            )}
          </div>
          <div className={classes.links}>
            <Link_SizeMediumStateDefaultBGL
              hide={{
                rightIcon: true,
              }}
              text={{
                text: props.text?.text || <div className={classes.text}>Footer link</div>,
              }}
            />
            <Link_SizeMediumStateDefaultBGL
              hide={{
                rightIcon: true,
              }}
              text={{
                text: props.text?.text2 || <div className={classes.text2}>Footer link</div>,
              }}
            />
            <Link_SizeMediumStateDefaultBGL
              hide={{
                rightIcon: true,
              }}
              text={{
                text: props.text?.text3 || <div className={classes.text3}>Footer link</div>,
              }}
            />
            {!props.hide?.link && (
              <Link_SizeMediumStateDefaultBGL
                hide={{
                  rightIcon: true,
                }}
                text={{
                  text: <div className={classes.text3}>Footer link</div>,
                }}
              />
            )}
            {!props.hide?.link2 && (
              <Link_SizeMediumStateDefaultBGL
                hide={{
                  rightIcon: true,
                }}
                text={{
                  text: <div className={classes.text4}>Footer link</div>,
                }}
              />
            )}
            {!props.hide?.link3 && (
              <Link_SizeMediumStateDefaultBGL
                hide={{
                  rightIcon: true,
                }}
                text={{
                  text: <div className={classes.text5}>Footer link</div>,
                }}
              />
            )}
            {!props.hide?.link4 && (
              <Link_SizeMediumStateDefaultBGL
                hide={{
                  rightIcon: true,
                }}
                text={{
                  text: <div className={classes.text6}>Footer link</div>,
                }}
              />
            )}
            {!props.hide?.link5 && (
              <Link_SizeMediumStateDefaultBGL
                swap={{
                  rightIcon: (
                    <NavigationOpen_in_new_24dp
                      className={classes.rightIcon}
                      swap={{
                        openInNew: <OpenInNewIcon className={classes.icon} />,
                      }}
                    />
                  ),
                }}
                text={{
                  text: <div className={classes.text7}>Footer external link</div>,
                }}
              />
            )}
            {!props.hide?.link6 && (
              <Link_SizeMediumStateDefaultBGL
                swap={{
                  rightIcon: (
                    <NavigationOpen_in_new_24dp
                      className={classes.rightIcon2}
                      swap={{
                        openInNew: <OpenInNewIcon2 className={classes.icon2} />,
                      }}
                    />
                  ),
                }}
                text={{
                  text: <div className={classes.text8}>Footer external link</div>,
                }}
              />
            )}
          </div>
        </div>
        <div className={classes.legalText}>
          {props.text?.thisIsPlaceholderTextThisIsWhe != null ? (
            props.text?.thisIsPlaceholderTextThisIsWhe
          ) : (
            <div className={classes.thisIsPlaceholderTextThisIsWhe}>
              This is placeholder text. This is where you should place your legal text and/or privacy notice if you need
              one. It is recommended that you consult with your legal advisors for text that is relevant and adequate
              for your context and use. UI Toolkit cannot offer advice on legal text or privacy notices, this is outside
              our remit as a design system. Thank you for using Better.UI.
            </div>
          )}
        </div>
      </div>
    </div>
  );
});
