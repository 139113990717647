import { memo } from 'react';
import type { FC, ReactNode } from 'react';

import resets from '../../_resets.module.css';
import { Footer_TypeDesktopLogoFalse } from '../Footer_TypeDesktopLogoFalse/Footer_TypeDesktopLogoFalse';
import classes from './FooterAreaGuest.module.css';

interface Props {
  className?: string;
  classes?: {
    root?: string;
  };
  hide?: {
    rightIcon?: boolean;
    rightIcon2?: boolean;
    link?: boolean;
    link2?: boolean;
    link3?: boolean;
    link4?: boolean;
    link5?: boolean;
    link6?: boolean;
  };
  text?: {
    _2023OptumIncAllRightsReserved?: ReactNode;
    text?: ReactNode;
    text2?: ReactNode;
    text3?:ReactNode;
    thisIsPlaceholderTextThisIsWhe?: ReactNode;
  };
}
/* @figmaId 1:21 */
export const FooterAreaGuest: FC<Props> = memo(function FooterAreaGuest(props = {}) {
  return (
    <div className={`${resets.storybrainResets} ${props.classes?.root || ''} ${props.className || ''} ${classes.root}`}>
      <div className={classes.bottomFooter}></div>
      <Footer_TypeDesktopLogoFalse
        className={classes.footer}
        classes={{ content: classes.content }}
        hide={{
          link: true,
          link2: true,
          link3: true,
          link4: true,
          link5: true,
          link6: true,
        }}
        text={{
          text: props.text?.text || <div className={classes.text}>Privacy policy</div>,
          text2: props.text?.text2 || <div className={classes.text2}>Terms of use</div>,
          text3:<div className={classes.text3}>____________________________________________________________________________________</div>,
          thisIsPlaceholderTextThisIsWhe: props.text?.thisIsPlaceholderTextThisIsWhe || (
            <div className={classes.thisIsPlaceholderTextThisIsWhe}>
              Optum Financial is a registered trademark of Optum, Inc. in the U.S. and other jurisdictions. Payment
              solutions, including Optum Pay® , are made possible by Optum Financial, Inc. and its subsidiaries
              (collectively “Optum”) and their service providers. Banking services are provided by Optum Bank®, Member
              FDIC, and other financial institutions. Because we are continuously improving our products and services,
              Optum reserves the right to change specifications without prior notice. Patented technology protected by
              one or more patents. Additional patents pending.
            </div>
          ),
          _2023OptumIncAllRightsReserved: props.text?._2023OptumIncAllRightsReserved,
        }}
      />
    </div>
  );
});
