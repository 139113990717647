import { memo } from 'react';
import type { FC, ReactNode } from 'react';

import resets from '../../_resets.module.css';
import { NavigationChevron_left_24dp } from '../NavigationChevron_left_24dp/NavigationChevron_left_24dp';
import { NavigationChevron_right_24dp } from '../NavigationChevron_right_24dp/NavigationChevron_right_24dp';
import { ChevronRightIcon } from './ChevronRightIcon';
import classes from './Link_SizeMediumStateDefaultBGL.module.css';

interface Props {
  className?: string;
  swap?: {
    rightIcon?: ReactNode;
  };
  hide?: {
    rightIcon?: boolean;
  };
  text?: {
    text?: ReactNode;
  };
}
/* @figmaId 1:164 */
export const Link_SizeMediumStateDefaultBGL: FC<Props> = memo(function Link_SizeMediumStateDefaultBGL(props = {}) {
  return (
    <div className={`${resets.storybrainResets} ${classes.root}`}>
      {props.text?.text != null ? props.text?.text : <div className={classes.text}>Link</div>}
      {!props.hide?.rightIcon &&
        (props.swap?.rightIcon || (
          <NavigationChevron_right_24dp
            className={classes.rightIcon}
            swap={{
              chevronRight: <ChevronRightIcon className={classes.icon} />,
            }}
          />
        ))}
    </div>
  );
});
