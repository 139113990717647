import { memo } from 'react';
import type { FC } from 'react';

import resets from '../_resets.module.css';
import { Arrow_forward_iosIcon } from './Arrow_forward_iosIcon';
import { Arrow_forward_iosIcon2 } from './Arrow_forward_iosIcon2';
import { Arrow_forward_iosIcon3 } from './Arrow_forward_iosIcon3';
import { Arrow_forward_iosIcon4 } from './Arrow_forward_iosIcon4';
import { Arrow_forward_iosIcon5 } from './Arrow_forward_iosIcon5';
import { Arrow_forward_iosIcon6 } from './Arrow_forward_iosIcon6';
import { Button_TypePrimarySizeMediumSt } from './Button_TypePrimarySizeMediumSt/Button_TypePrimarySizeMediumSt';
import { Button_TypeSecondarySizeMedium } from './Button_TypeSecondarySizeMedium/Button_TypeSecondarySizeMedium';
import { ButtonsUpdatedColors_StateSeco } from './ButtonsUpdatedColors_StateSeco/ButtonsUpdatedColors_StateSeco';
import { Card } from './Card/Card';
import { FooterAreaGuest } from './FooterAreaGuest/FooterAreaGuest';
import { HeaderPublicPage } from './HeaderPublicPage/HeaderPublicPage';
import { IconsMediumUser } from './IconsMediumUser/IconsMediumUser';
import { OPPNavigationLinks } from './OPPNavigationLinks/OPPNavigationLinks';
import { OptumFinancialLogoIcon } from './OptumFinancialLogoIcon';
import { OptumFinancialLogoIcon2 } from './OptumFinancialLogoIcon2';
import { OptumLogoOraRgb11Icon } from './OptumLogoOraRgb11Icon';
import { Person_filledIcon } from './Person_filledIcon';
import classes from './TruULPDraft1.module.css';

interface Props {
  className?: string;
  hide?: {
    rightIcon?: boolean;
    rightIcon2?: boolean;
    link?: boolean;
    link2?: boolean;
    link3?: boolean;
    link4?: boolean;
    link5?: boolean;
    link6?: boolean;
    image?: boolean;
    heading?: boolean;
    button?: boolean;
  };
}
/* @figmaId 1:605 */
export const TruULPDraft1: FC<Props> = memo(function TruULPDraft1(props = {}) {
  const queryParameters = new URLSearchParams(window.location.search);
  const testQp = queryParameters.get("test");
  let ssoLink = "https://ohpsppe.idp.id.truu.ai/saml/awMgsRAxSv041vBYvM6bFasxoox4Kf02uBM4/idp?RelayState=https://identity.nonprod.onehealthcareid.com/web/sso/truu_to_optumpay"; // TruU SSO
  if (testQp === "1") {  // IAM Showcase
    ssoLink = "https://ohpsppe.idp.id.truu.ai/saml/Zc5lrw1I20eyCcY0CTWrAfSPrXuOm5UxQDb6/idp"
  }
  return (
    <div className={`${resets.storybrainResets} ${classes.root}`}>
      <FooterAreaGuest
        className={`${classes.footerAreaGuest} ${classes.footerLinks}`}
        text={{
          _2023OptumIncAllRightsReserved: (
            <div className={`${classes._2023OptumIncAllRightsReserved}`}>© 2023 Optum Inc. All rights reserved. </div>
          ),
          text: <div className={`${classes.text} `}>Privacy policy</div>,
          text2: <div className={`${classes.text2}`}>Terms of use </div>,
          text3: <div className={`${classes.text3}`}>Terms of use </div>,
          thisIsPlaceholderTextThisIsWhe: (
            <div className={classes.thisIsPlaceholderTextThisIsWhe}>
              Optum Financial is a registered trademark of Optum, Inc. in the U.S. and other jurisdictions. Payment
              solutions, including Optum Pay® , are made possible by Optum Financial, Inc. and its subsidiaries
              (collectively “Optum”) and their service providers. Banking services are provided by Optum Bank®, Member
              FDIC, and other financial institutions. Because we are continuously improving our products and services,
              Optum reserves the right to change specifications without prior notice. Patented technology protected by
              one or more patents. Additional patents pending.
            </div>
          ),
        }}
      />
      <HeaderPublicPage
        swap={{
          optumFinancialLogo: <OptumFinancialLogoIcon2 className={classes.icon} />,
        }}
      />
      <Card
        className={classes.card}
        classes={{
          headingImage: classes.headingImage,
          subheadingContent: classes.subheadingContent,
          button: classes.button,
          cTA: classes.cTA,
        }}
        hide={{
          image: true,
          heading: true,
          button: true,
        }}
        text={{
          heading: <div className={classes.heading}>Enroll</div>,
          bodyCopy: (
            <div className={classes.bodyCopy}>
              Enroll with TruU to enable account authentication for Optum Pay® with an industry leading, secure
              passwordless login process.{' '}
            </div>
          ),
          //buttonLink: "https://ohpsppe.portal.id.truu.ai/auth/login",
          buttonLink: "https://start.ohpsppe.truu.ai",
          buttonLabel: <div className={classes.buttonLabel}>Enroll with TruU</div>,
        }}
      />
      <div className={classes.card2}>
        <div className={classes.headingImage2}>
          <div className={classes.heading2}>
            <div className={classes.heading3}>Sign in</div>
          </div>
        </div>
        <div className={classes.mainContent}>
          <div className={classes.subheadingContent2}>
            <div className={classes.bodyCopy2}>Access Optum Pay® with secure, passwordless authentication.</div>
          </div>
          <div className={classes.cTA2}>
            <a href={ssoLink}>
            <Button_TypePrimarySizeMediumSt
              text={{
                buttonLabel: <div className={classes.buttonLabel2}>Sign in</div>,
              }}
            />
            </a>
          </div>
        </div>
      </div>
      <div className={classes.header}>
        <div className={classes.frame7083}>
          <div className={classes.accessOptumPayWith}>Access Optum Pay® with </div>
          <div className={classes.image1}></div>
        </div>
        <div className={classes.bodyCopy3}>Secure, user-friendly, passwordless account logins.</div>
      </div>
    </div>
  );
});
