import { memo, SVGProps } from 'react';

const OpenInNewIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M15 16H3C2.4 16 2 15.5 2 15V3C2 2.4 2.4 2 3 2H8C8.6 2 9 1.6 9 1C9 0.4 8.6 0 8 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V10C18 9.4 17.5 9 17 9C16.5 9 16 9.4 16 10V15C16 15.5 15.5 16 15 16ZM11 1C11 1.6 11.4 2 12 2H14.6L5.5 11.1C5.1 11.5 5.1 12.1 5.5 12.5C5.9 12.9 6.5 12.9 6.9 12.5L16 3.4V6C16 6.6 16.5 7 17 7C17.5 7 18 6.6 18 6V1C18 0.5 17.5 0 17 0H12C11.4 0 11 0.5 11 1Z'
      fill='#4B4D4F'
    />
  </svg>
);
const Memo = memo(OpenInNewIcon);
export { Memo as OpenInNewIcon };
