import { memo, SVGProps } from 'react';

const ChevronRightIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M0.975 1.025C0.675 1.325 0.675 1.775 0.975 2.075L3.9 5L0.975 7.925C0.675 8.225 0.675 8.675 0.975 8.975C1.275 9.275 1.725 9.275 2.025 8.975L5.475 5.525C5.775 5.225 5.775 4.775 5.475 4.475L2.025 1.025C1.725 0.725 1.275 0.725 0.975 1.025Z'
      fill='#0C55B8'
    />
  </svg>
);
const Memo = memo(ChevronRightIcon);
export { Memo as ChevronRightIcon };
