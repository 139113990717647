import { memo } from 'react';
import type { FC, ReactNode } from 'react';

import resets from '../../_resets.module.css';
import { Button_TypePrimarySizeMediumSt } from '../Button_TypePrimarySizeMediumSt/Button_TypePrimarySizeMediumSt';
import { Button_TypeSecondarySizeMedium } from '../Button_TypeSecondarySizeMedium/Button_TypeSecondarySizeMedium';
import classes from './Card.module.css';

interface Props {
  className?: string;
  classes?: {
    headingImage?: string;
    subheadingContent?: string;
    button?: string;
    cTA?: string;
    root?: string;
  };
  hide?: {
    image?: boolean;
    heading?: boolean;
    button?: boolean;
  };
  text?: {
    heading?: ReactNode;
    bodyCopy?: ReactNode;
    buttonLabel?: ReactNode;
    buttonLink?: string;
  };
}
/* @figmaId 1:332 */
export const Card: FC<Props> = memo(function Card(props = {}) {
  return (
    <div className={`${resets.storybrainResets} ${props.classes?.root || ''} ${props.className || ''} ${classes.root}`}>
      <div className={`${props.classes?.headingImage || ''} ${classes.headingImage}`}>
        <div className={classes.heading}>
          {props.text?.heading != null ? props.text?.heading : <div className={classes.heading2}>Heading</div>}
        </div>
        {!props.hide?.image && (
          <div className={classes.image}>
            <div className={classes.image2}></div>
          </div>
        )}
      </div>
      <div className={classes.mainContent}>
        <div className={`${props.classes?.subheadingContent || ''} ${classes.subheadingContent}`}>
          {!props.hide?.heading && <div className={classes.heading3}>Subheading</div>}
          {props.text?.bodyCopy != null ? (
            props.text?.bodyCopy
          ) : (
            <div className={classes.bodyCopy}>
              Body 2a dolor sit amet, consectetur adipiscing elit. Egestas facilisi varius ligula feugiat enim, commodo
              risus, fames. Non sed suspendisse orci, magna.
            </div>
          )}
        </div>
        <div className={`${props.classes?.cTA || ''} ${classes.cTA}`}>
          <a href={`${props.text?.buttonLink}`}>
          <Button_TypePrimarySizeMediumSt
            className={`${props.classes?.button || ''} ${classes.button}`}
            text={{
              buttonLabel: props.text?.buttonLabel,
            }}
          />
          {!props.hide?.button && <Button_TypeSecondarySizeMedium className={classes.button2} />}
          </a>
        </div>
      </div>
    </div>
  );
});
