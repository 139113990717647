import { memo, SVGProps } from 'react';

const OptumFinancialLogoIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 250 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#clip0_13_2931)'>
      <path
        d='M80.6007 24.901V6.44369H86.3891V8.62799C87.5904 6.98976 89.2287 6.11604 91.3038 6.11604C93.8157 6.11604 95.5631 7.31741 96.5461 9.06485C97.7474 7.09898 99.9317 6.11604 102.225 6.11604C106.266 6.11604 108.451 9.17406 108.451 12.9966V24.7918H102.553V13.8703C102.553 12.1229 101.461 11.0307 100.041 11.0307C98.6212 11.0307 97.529 12.1229 97.529 13.8703V24.7918H91.6314V13.8703C91.6314 12.1229 90.5393 11.0307 89.1195 11.0307C87.6997 11.0307 86.6075 12.1229 86.6075 13.8703V24.7918H80.6007V24.901ZM78.6348 17.802C78.6348 22.4983 74.8123 25.2287 70.0068 25.2287C65.2014 25.2287 61.3788 22.4983 61.3788 17.802V6.44369H67.3857V17.6928C67.3857 19.3311 68.4778 20.314 70.0068 20.314C71.5358 20.314 72.628 19.3311 72.628 17.6928V6.44369H78.6348V17.802ZM59.8498 11.1399V6.44369H54.9352V1.74744H49.0375V18.2389C49.0375 22.8259 51.7679 25.1195 56.1365 25.1195C57.4471 25.1195 58.7577 25.0102 59.959 24.4642V19.7679C59.0853 20.0956 58.3208 20.0956 57.6655 20.0956C56.1365 20.0956 55.0444 19.3311 55.0444 17.6928V11.1399H59.8498ZM41.5017 15.6177C41.5017 18.2389 39.5358 20.2048 37.2423 20.2048C34.8396 20.2048 32.8737 18.2389 32.8737 15.6177C32.8737 12.9966 34.8396 11.0307 37.2423 11.0307C39.5358 11.1399 41.5017 12.9966 41.5017 15.6177ZM32.8737 32V22.9352C34.4027 24.3549 36.3686 25.1195 38.5529 25.1195C43.5768 25.1195 47.3993 20.8601 47.3993 15.6177C47.3993 10.3754 43.4676 6.11604 38.5529 6.11604C36.2594 6.11604 34.1843 6.98976 32.6553 8.51877V6.44369H26.8669V32H32.8737ZM18.785 12.8874C18.785 16.7099 16.0546 19.4403 12.5597 19.4403C9.06485 19.4403 6.33447 16.6007 6.33447 12.8874C6.33447 9.06485 9.06485 6.33447 12.5597 6.33447C16.0546 6.33447 18.785 9.06485 18.785 12.8874ZM12.5597 0.546074C5.56997 0.546074 0 6.00682 0 12.8874C0 19.7679 5.56997 25.1195 12.5597 25.1195C19.5495 25.1195 25.1195 19.6587 25.1195 12.8874C25.1195 6.11604 19.5495 0.546074 12.5597 0.546074Z'
        fill='#FF612B'
      />
      <path
        d='M118.389 24.901V0.982937H134.116V3.27645H121.229V11.3584H131.167V13.7611H121.229V24.901H118.389Z'
        fill='#5A5A5A'
      />
      <path
        d='M136.519 2.07509C136.519 1.09215 137.393 0.21843 138.375 0.21843C139.358 0.21843 140.232 1.09215 140.232 2.07509C140.232 3.05802 139.358 3.93174 138.375 3.93174C137.283 3.93174 136.519 3.16724 136.519 2.07509ZM137.065 7.20819H139.577V24.7918H137.065V7.20819Z'
        fill='#5A5A5A'
      />
      <path
        d='M143.727 7.20819H146.239V9.17406C147.659 7.64505 149.734 6.77133 151.918 6.77133C155.741 6.77133 158.253 9.39249 158.253 13.215V24.7918H155.741V13.7611C155.741 10.7031 153.993 9.17406 151.481 9.17406C149.734 9.17406 147.877 9.82935 146.239 11.5768V24.901H143.727V7.20819V7.20819Z'
        fill='#5A5A5A'
      />
      <path
        d='M160.874 20.5324C160.874 17.4744 162.621 15.8362 167.099 15.0717L172.341 14.0887V13.3242C172.341 10.8123 170.703 9.06485 168.082 9.06485C165.57 9.06485 164.15 10.4846 163.604 12.6689L161.201 12.1229C161.747 9.39249 163.932 6.88055 168.191 6.88055C172.451 6.88055 174.853 9.50171 174.853 13.3242V24.7918H172.341V22.4983C170.812 24.0273 168.628 25.1195 166.334 25.1195C163.058 25.2287 160.874 23.2628 160.874 20.5324ZM166.771 23.0444C169.065 23.0444 170.922 21.9522 172.341 20.314V16.1638L168.628 16.8191C164.696 17.5836 163.495 18.4573 163.495 20.2048C163.495 21.9522 164.696 23.0444 166.771 23.0444Z'
        fill='#5A5A5A'
      />
      <path
        d='M178.239 7.20819H180.751V9.17406C182.171 7.64505 184.246 6.77133 186.43 6.77133C190.253 6.77133 192.764 9.39249 192.764 13.215V24.7918H190.253V13.7611C190.253 10.7031 188.505 9.17406 185.993 9.17406C184.246 9.17406 182.389 9.82935 180.751 11.5768V24.901H178.239V7.20819V7.20819Z'
        fill='#5A5A5A'
      />
      <path
        d='M211.113 11.4676L208.71 12.5597C207.727 10.5939 206.416 9.28328 203.686 9.28328C200.41 9.28328 197.898 12.1229 197.898 16.0546C197.898 20.2048 200.41 22.9352 203.577 22.9352C206.416 22.9352 207.727 21.6246 208.819 19.6587L211.113 20.6416C209.693 23.372 207.29 25.3379 203.904 25.3379C198.99 25.3379 195.386 21.4061 195.386 16.0546C195.386 10.8123 198.99 6.88055 203.904 6.88055C207.181 6.88055 209.693 8.51877 211.113 11.4676Z'
        fill='#5A5A5A'
      />
      <path
        d='M213.297 2.07509C213.297 1.09215 214.171 0.21843 215.154 0.21843C216.137 0.21843 217.01 1.09215 217.01 2.07509C217.01 3.05802 216.137 3.93174 215.154 3.93174C214.171 3.93174 213.297 3.16724 213.297 2.07509ZM213.843 7.20819H216.355V24.7918H213.843V7.20819Z'
        fill='#5A5A5A'
      />
      <path
        d='M219.522 20.5324C219.522 17.4744 221.27 15.8362 225.747 15.0717L230.99 14.0887V13.3242C230.99 10.8123 229.352 9.06485 226.73 9.06485C224.218 9.06485 222.799 10.4846 222.253 12.6689L219.85 12.1229C220.396 9.39249 222.58 6.88055 226.84 6.88055C231.099 6.88055 233.502 9.50171 233.502 13.3242V24.7918H230.99V22.4983C229.461 24.0273 227.276 25.1195 224.983 25.1195C221.706 25.2287 219.522 23.2628 219.522 20.5324ZM225.42 23.0444C227.713 23.0444 229.57 21.9522 230.99 20.314V16.1638L227.276 16.8191C223.345 17.5836 222.143 18.4573 222.143 20.2048C222.143 21.9522 223.454 23.0444 225.42 23.0444Z'
        fill='#5A5A5A'
      />
      <path d='M237.106 0H239.618V24.901H237.106V0Z' fill='#5A5A5A' />
      <path
        d='M245.952 8.19113C243.986 8.19113 242.348 6.66212 242.348 4.58703C242.348 2.51195 243.986 0.982937 245.952 0.982937C247.918 0.982937 249.556 2.51195 249.556 4.58703C249.556 6.66212 247.918 8.19113 245.952 8.19113ZM245.952 7.53584C247.59 7.53584 248.792 6.33447 248.792 4.58703C248.792 2.94881 247.59 1.63823 245.952 1.63823C244.423 1.63823 243.113 2.83959 243.113 4.58703C243.113 6.33447 244.314 7.53584 245.952 7.53584ZM244.642 6.33447V2.94881H246.171C246.826 2.94881 247.263 3.27645 247.263 3.93174C247.263 4.25939 247.044 4.58703 246.717 4.80546L247.59 6.33447H246.826L246.061 5.02389H245.515V6.33447H244.642V6.33447ZM246.061 3.49488H245.406V4.3686H246.061C246.389 4.3686 246.608 4.15017 246.608 3.93174C246.498 3.6041 246.28 3.49488 246.061 3.49488Z'
        fill='#5A5A5A'
      />
    </g>
    <defs>
      <clipPath id='clip0_13_2931'>
        <rect width={250} height={32} fill='white' />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(OptumFinancialLogoIcon);
export { Memo as OptumFinancialLogoIcon };
