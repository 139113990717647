import { memo } from 'react';
import type { FC, ReactNode } from 'react';

import resets from '../../_resets.module.css';
import { ChevronRightIcon } from './ChevronRightIcon';
import classes from './NavigationChevron_right_24dp.module.css';

interface Props {
  className?: string;
  classes?: {
    root?: string;
  };
  swap?: {
    chevronRight?: ReactNode;
  };
}
/* @figmaId 1:303 */
export const NavigationChevron_right_24dp: FC<Props> = memo(function NavigationChevron_right_24dp(props = {}) {
  return (
    <div className={`${resets.storybrainResets} ${props.classes?.root || ''} ${props.className || ''} ${classes.root}`}>
      <div className={classes.chevronRight}>
        {props.swap?.chevronRight || <ChevronRightIcon className={classes.icon} />}
      </div>
    </div>
  );
});
