import { memo, SVGProps } from 'react';

const ChevronRightIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 7 12' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M0.3 0.7C-0.1 1.1 -0.1 1.7 0.3 2.1L4.2 6L0.3 9.9C-0.1 10.3 -0.1 10.9 0.3 11.3C0.7 11.7 1.3 11.7 1.7 11.3L6.3 6.7C6.7 6.3 6.7 5.7 6.3 5.3L1.7 0.7C1.3 0.3 0.7 0.3 0.3 0.7Z'
      fill='#4B4D4F'
    />
  </svg>
);
const Memo = memo(ChevronRightIcon);
export { Memo as ChevronRightIcon };
